// taken from https://raw.githubusercontent.com/alexradulescu/FreezeUI and modified
(() => {
    let freezedItems = [];

    let getSelector = (selector) => (selector ? selector : "body");

    let normalizeFreezeDelay = (delay) => (delay ? delay : 250);

    let addFreezedItem = (selector) => {
        let itemSelector = getSelector(selector);
        freezedItems.push(itemSelector);
    };

    let removeFreezedItem = (selector) => {
        let itemSelector = getSelector(selector);
        for (let i = 0; i < freezedItems.length; i++) {
            if (freezedItems[i] === itemSelector) {
                freezedItems.splice(i, 1);
            }
        }
    };

    var queryChildren = function (element, selector) {
        return Array.prototype.find.call(element.children, function (child) {
            return child.matches(selector);
        });
    }

    window.FreezeUI = (options = {}) => {
        addFreezedItem(options.selector);

        let parent;
        if (options.element) {
            parent = options.element;
        } else {
            parent = document.querySelector(options.selector) || document.body;
        }

        var existentFreeze = queryChildren(parent, ".freeze-ui");
        if (existentFreeze)
            return;

        let freezeHtml = document.createElement("div");
        freezeHtml.classList.add("freeze-ui");

        freezeHtml.setAttribute("data-text", options.text || "Loading");

        if (document.querySelector(options.selector) || options.element) {
            freezeHtml.style.position = "absolute";
        }

        parent.appendChild(freezeHtml);
    };

    window.UnFreezeUI = (options = {}) => {
        removeFreezedItem(options.selector);

        setTimeout(() => {
            let freezeHtml;
            if (options.element) {
                freezeHtml = options.element.querySelector(".freeze-ui");
            } else {
                if (options.selector) {
                    var element = document.querySelector(options.selector);
                    if (element) {
                        freezeHtml = element.querySelector(".freeze-ui");
                    }
                } else {
                    freezeHtml = queryChildren(document.body, ".freeze-ui");
                }
            }

            if (freezeHtml) {
                if (freezeHtml.classList) {
                    freezeHtml.classList.remove("is-unfreezing");
                }
                if (freezeHtml.parentElement) {
                    freezeHtml.parentElement.removeChild(freezeHtml);
                }
            }
        });
    };
})();
